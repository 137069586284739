* {
  box-sizing: border-box;
}

a {
  text-decoration: none;
}

body {
  display: flex;
  justify-content: center;
  flex-direction: column;
  // background-image: url("../aviator/assets/bg.webp");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  background-color: #fff;
}

.wrapper {
  max-width: 428px;
  width: 100%;
  margin: 0 auto;
  padding: 0 12px;
  margin-top: 80px;
}

.container {
  position: relative;
  max-width: 402px;
  width: 100%;
  padding: 12px;
  margin: 0 auto;

  border-radius: 18px;
  border: 2px solid #616161;
  background: #191a1c;
}

.displayNone {
  display: none;
}

.btn__start {
  max-width: 378px;
  width: 100%;
  height: 73px;
  margin-top: 12px;

  border-radius: 18px;
  border: transparent;

  cursor: pointer;
  text-align: center;
  font-family: "Inter", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  color: #fff;
  text-transform: uppercase;
  background-color: #28a809;
  box-shadow: 0px 3px 0px 0px rgba(255, 255, 255, 0.25) inset;
}

.btn_start--active {
  background-color: #d97400;
}

.btn__accept {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 0;

  border-radius: 12px;
  background: linear-gradient(225deg, #0a63e9 0%, #1fa1ff 100%), #28a809;
  box-shadow: 0px 3px 0px 0px rgba(255, 255, 255, 0.25) inset;
}

.btn__icon {
  margin-right: 10px;
}

.btn__decline {
  margin-top: 10px;

  border-radius: 12px;
  background: #d97400;
  box-shadow: 0px 3px 0px 0px rgba(255, 255, 255, 0.25) inset;
}

.notification {
  position: absolute;
  border-radius: 100px;
  border: 2px solid #4e6c2d;
  background: #1a320b;
  max-width: 304px;
  width: 100%;
  left: 50%;
  top: 21px;
  padding: 10px 30px;
  height: 75px;
  display: flex;
  transform: translateX(-50%);

  z-index: 1;
}

.notification__text {
  color: #a0ac98;
  text-align: center;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-top: 3px;
}

.notification__text--right {
  color: #fff;
  text-align: center;
  text-shadow: 0px 1px 1px rgba(0, 0, 0, 0.25);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.notification__text--value {
  color: #e3eddd;
  text-align: center;
  text-shadow: 0px 1px 1px rgba(0, 0, 0, 0.25);
  font-family: Inter;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.notification__wrapper--right {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-left: 10px;
  padding: 10px 20px;

  border-radius: 100px;
  background: #7fb343;
}

.notification__icon {
  position: absolute;
  top: 50%;
  right: -5px;
  transform: translate(-50%, -50%);
}

.coefficient {
  color: #e3eddd;
  text-align: center;
  font-family: Inter;
  font-size: 27px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.modal__title {
  color: #fff;
  text-align: center;
  font-family: "Inter", sans-serif;
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
}

.btn__animation {
  animation: btn 500ms linear alternate;
}

.btn__start:disabled {
  background-color: #4e4e4e;
}

.graph__wrapper {
  position: relative;
}

.count {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  z-index: 999;

  display: block;

  color: #fff;
  text-align: center;
  font-family: "Inter", sans-serif;
  font-size: 44px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
}

.planeBg {
  position: absolute;
  bottom: -68%;
  left: 5%;
  animation: flying 5000ms ease 200ms;
}

.is-hidden {
  position: absolute;
  white-space: nowrap;
  width: 1px;
  height: 1px;
  overflow: hidden;
  border: 0;
  padding: 0;
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  margin: -1px;
}

.slide {
  -webkit-animation: slide-top 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: slide-top 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}
.background {
  background-image: url("../aviator/assets/redBg.webp");
  background-position: bottom;
  background-repeat: no-repeat;
  position: relative;

  max-width: 378px;
  height: 275px;

  overflow: hidden;

  border-radius: 12px;
  border: 2px solid #5d5d5d;
  background-color: #000;
}

.img__start {
  max-width: 378px;
  width: 100%;
  height: 275px;
}

.img__bottom {
  position: absolute;
  bottom: -275px;
  left: 50%;
  transform: translateX(-50%);

  max-width: 428px;
  width: 100%;
}

.backdrop__aviator {
  position: fixed;
  top: 0;
  left: 0;

  width: 100%;
  height: 100%;
  padding: 0 13px;

  background: rgba(0, 0, 0, 0.6);
  backdrop-filter: blur(11px);

  opacity: 1;
  transition: opacity 250ms cubic-bezier(0.4, 0, 0.2, 1);
}
.backdrop__aviator.is-hidden {
  opacity: 0;
  pointer-events: none;
}
.backdrop__aviator.is-hidden .modal__aviator {
  transform: translate(-50%, -50%) scale(0.9);
  transition: transform 250ms cubic-bezier(0.4, 0, 0.2, 1);
}
.modal__aviator {
  position: absolute;
  top: 50%;
  left: 50%;

  display: flex;
  flex-direction: column;

  max-width: 402px;
  width: 93%;
  height: 286px;
  padding: 22px 12px;
  gap: 12px;

  border-radius: 12px;
  border: 2px solid #5d5d5d;
  background-color: #18191b;

  transform: scale(1) translate(-50%, -50%);
  transition: transform 250ms cubic-bezier(0.4, 0, 0.2, 1);
}

.modal__pretitle {
  color: #fff;
  text-align: center;
  font-family: Inter;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
}

.modal__text {
  font-family: "Inter", sans-serif;
  text-align: center;
  color: #fff500;
  font-size: 60px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
}

.modal__text--bg {
  color: #fff500;
  text-align: center;
  font-family: Inter;
  font-size: 70px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
}
.modal__text--last {
  color: #fff;
  text-align: center;
  font-family: Inter;
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
}

@media screen and (max-width: 470px) {
  .modal__title {
    font-size: 32px;
  }

  .modal__text--bg {
    font-size: 60px;
  }

  .notification__text {
    margin-top: 0;
  }

  .notification__wrapper--right {
    margin-right: 0px;
    padding: 10px 13px;
  }
}

@media screen and (max-width: 425px) {
  .notification {
    width: 91%;
  }

  .notification__wrapper--right {
    margin-right: 3px;
    padding: 10px 15px;
  }
}

@media screen and (max-width: 425px) {
  .notification__text {
    font-size: 13px;
  }
}
@media screen and (max-width: 380px) {
  .modal {
    gap: 24px;
  }
  .modal__title {
    font-size: 28px;
  }

  .modal__pretitle {
    font-size: 26px;
  }

  .modal__text--bg {
    font-size: 54px;
  }

  .notification__text {
    font-size: 12px;
  }

  .notification__text--value {
    font-size: 20px;
  }

  .coefficient {
    font-size: 20px;
  }
}
@media screen and (max-width: 360px) {
  .modal__text {
    font-size: 46px;
  }
}

@media screen and (max-width: 350px) {
  .modal__title {
    font-size: 24px;
  }

  .modal__pretitle {
    font-size: 22px;
  }

  .modal__text--bg {
    font-size: 50px;
  }

  .notification__wrapper--right {
    margin-right: 0;
    padding: 10px 12px;
  }
}

@-webkit-keyframes slide-top {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
  100% {
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
  }
}
@keyframes slide-top {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
  100% {
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
  }
}

@keyframes btn {
  0% {
    transform: rotate3d(1, 1, 1, 0deg);
  }

  25% {
    transform: rotate3d(1, 1, 1, -5deg);
    background-color: #3ddb17;
  }

  50% {
    transform: rotate3d(1, 1, 1, 0deg);
  }

  75% {
    transform: rotate3d(1, 1, 1, 5deg);
    background-color: #3ddb17;
  }

  100% {
    transform: rotate3d(1, 1, 1, 0deg);
  }
}
@keyframes flying {
  0% {
    bottom: -67.2%;
    left: 5%;
  }
  2% {
    bottom: -67%;
    left: 7%;
  }
  4% {
    bottom: -67%;
    left: 9%;
  }
  6% {
    bottom: -66.5%;
    left: 11%;
  }
  8% {
    bottom: -66%;
    left: 13%;
  }
  10% {
    bottom: -65.5%;
    left: 15%;
  }
  12% {
    bottom: -65%;
    left: 17%;
  }

  14% {
    bottom: -64.5%;
    left: 19%;
  }

  16% {
    bottom: -64%;
    left: 21%;
  }

  18% {
    bottom: -63.5%;
    left: 23%;
  }
  20% {
    bottom: -63%;
    left: 25%;
  }
  22% {
    bottom: -62.5%;
    left: 27%;
  }
  24% {
    bottom: -62%;
    left: 29%;
  }
  26% {
    bottom: -61.5%;
    left: 31%;
  }
  28% {
    bottom: -61%;
    left: 33%;
  }
  30% {
    bottom: -60%;
    left: 35%;
  }
  32% {
    bottom: -59%;
    left: 37%;
  }

  34% {
    bottom: -57%;
    left: 39%;
  }
  36% {
    bottom: -55%;
    left: 41%;
  }
  38% {
    bottom: -53%;
    left: 43%;
  }
  40% {
    bottom: -51%;
    left: 45%;
  }
  42% {
    bottom: -49%;
    left: 47%;
  }
  42% {
    bottom: -47%;
    left: 49%;
  }
  44% {
    bottom: -46%;
    left: 51%;
  }
  46% {
    bottom: -45%;
    left: 53%;
  }
  48% {
    bottom: -44%;
    left: 55%;
  }
  50% {
    bottom: -43%;
    left: 57%;
  }
  52% {
    bottom: -42%;
    left: 59%;
  }

  54% {
    bottom: -41%;
    left: 60%;
  }
  56% {
    bottom: -40%;
    left: 61%;
  }
  58% {
    bottom: -39%;
    left: 62%;
  }
  60% {
    bottom: -38%;
    left: 63%;
  }
  62% {
    bottom: -37%;
    left: 64%;
  }
  64% {
    bottom: -36%;
    left: 65%;
  }
  66% {
    bottom: -35%;
    left: 66%;
  }
  68% {
    bottom: -34%;
    left: 67%;
  }
  70% {
    bottom: -32%;
    left: 68%;
  }
  72% {
    bottom: -31%;
    left: 69%;
  }
  74% {
    bottom: -30%;
    left: 70%;
  }

  76% {
    bottom: -29%;
    left: 71%;
  }
  78% {
    bottom: -28%;
    left: 72%;
  }
  80% {
    bottom: -27%;
    left: 73%;
  }
  82% {
    bottom: -26%;
    left: 74%;
  }
  84% {
    bottom: -25%;
    left: 75%;
  }

  86% {
    bottom: -23.5%;
    left: 76%;
  }
  88% {
    bottom: -22%;
    left: 77%;
  }
  90% {
    bottom: -20.5%;
    left: 78%;
  }

  92% {
    bottom: -19%;
    left: 79%;
  }
  94% {
    bottom: -17.5%;
    left: 80%;
  }
  96% {
    bottom: -16%;
    left: 81%;
  }
  98% {
    bottom: -14.5%;
    left: 82%;
  }
  100% {
    bottom: -13%;
    left: 84%;
  }
}
