.game-body-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    max-width: 1000px;
    margin: 0 auto;
}

.textTitle {
    text-transform: uppercase;
    font-size: 32px;
    font-weight: 800;
    line-height: 35px;
    letter-spacing: 0em;
    text-align: center;
    color: #ffffff;
}

.mainText {
    font-family: 'Montserrat', sans-serif;
    font-size: 47px;
    font-weight: 800;
    line-height: 51px;
    letter-spacing: 0em;
    text-align: center;
    color: #ffffff;
    margin-top: 20px;
}

.displayNone {
    display: none;
}

.text {
    font-family: 'Montserrat', sans-serif;
    font-size: 27px;
    font-weight: 600;
    line-height: 29px;
    letter-spacing: 0em;
    text-align: center;
    color: #ffffff;
    margin-bottom: 28px;
}

.yellowText {
    font-family: 'Montserrat', sans-serif;
    font-size: 41px;
    font-weight: 800;
    line-height: 45px;
    letter-spacing: 0em;
    text-align: center;
    color: #ffd913;
    margin-bottom: 4px;
}

.middleText {
    font-family: 'Montserrat', sans-serif;
    font-size: 32px;
    font-weight: 800;
    line-height: 35px;
    letter-spacing: 0em;
    text-align: center;
    color: #ffffff;
}

.middleText__bg {
    background: linear-gradient(180deg, #ff0909 0%, #820e0e 100%);
    height: 55px;
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 381px;
    width: 100%;
}

.button {
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 329px;
    width: 95%;
    height: 68px;
    margin: 42px auto 0;
    background-color: #ffd913;
    font-family: 'Montserrat', sans-serif;
    font-size: 31px;
    font-weight: 800;
    line-height: 34px;
    letter-spacing: 0em;
    text-align: center;
    color: #000000;
    text-transform: uppercase;
    border-radius: 12px;
}

@media screen and (max-width: 390px) {
    .textTitle {
        font-size: 29px;
    }
}

@media screen and (max-width: 370px) {
    .mainText {
        font-size: 43px;
        margin-top: 48px;
    }

    .text {
        margin-bottom: 10px;
    }

    .yellowText {
        font-size: 32px;
    }

    .button {
        width: 90%;
        margin-top: 15px;
        font-size: 26px;
    }

    .textTitle {
        font-size: 25px;
    }
}

.backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    opacity: 1;
    -webkit-transition: opacity 250ms cubic-bezier(0.4, 0, 0.2, 1);
    transition: opacity 250ms cubic-bezier(0.4, 0, 0.2, 1);
}

.backdrop.is-hidden {
    opacity: 0;
    pointer-events: none;
}

.backdrop.is-hidden .modal {
    -webkit-transform: translate(-50%, -50%) scale(0.9);
    transform: translate(-50%, -50%) scale(0.9);
    -webkit-transition: -webkit-transform 250ms cubic-bezier(0.4, 0, 0.2, 1);
    transition: -webkit-transform 250ms cubic-bezier(0.4, 0, 0.2, 1);
    transition: transform 250ms cubic-bezier(0.4, 0, 0.2, 1);
    transition: transform 250ms cubic-bezier(0.4, 0, 0.2, 1),
        -webkit-transform 250ms cubic-bezier(0.4, 0, 0.2, 1);
}

.modal {
    background-image: url('../plinko/assets/POP.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    position: absolute;
    top: 50%;
    left: 50%;
    max-width: 381px;
    width: 95%;
    height: 379px;
    padding: 0 5px;
    -webkit-transform: scale(1) translate(-50%, -50%);
    transform: scale(1) translate(-50%, -50%);
    -webkit-transition: -webkit-transform 250ms cubic-bezier(0.4, 0, 0.2, 1);
    transition: -webkit-transform 250ms cubic-bezier(0.4, 0, 0.2, 1);
    transition: transform 250ms cubic-bezier(0.4, 0, 0.2, 1);
    transition: transform 250ms cubic-bezier(0.4, 0, 0.2, 1),
        -webkit-transform 250ms cubic-bezier(0.4, 0, 0.2, 1);
}